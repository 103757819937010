import { Component, Injector, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { AbstractComponent, Flow, FlowExecution, FlowService, UtilHelper } from 'lib-trend-core';
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'flow-embeded-component',
  templateUrl: 'flow-embeded.component.html',
})
export class FlowEmbededComponent extends AbstractComponent implements OnInit {

  flow: Flow;
  urlEmbeded: SafeResourceUrl = undefined

  constructor(
    injector: Injector,
    private domSanitizer: DomSanitizer,
    public flowService: FlowService,
  ) {
    super(injector)
  }

  ngOnInit() {
    const idUser = super.getIDCurrentUser();
    const idCompany = super.getIDCurrentCompany();
    const base64 = UtilHelper.generateTokenTowardsApps(idUser, idCompany);
    const idFlow = super.getParam('idFlow');
    const url = `${environment.trendFlow}?token=${base64}&idFlow=${idFlow}`;
    this.urlEmbeded = this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    this.getFlow(idFlow)
  }

  private getFlow(idFlow: string) {
    this.flowService.getById(idFlow).subscribe({
      next: (flow: Flow) => this.flow = flow
    });
  }

  triggerFlow() {
    const idFlow = this.flow._id;
    const idChannel = '673132ca07dea83246f94474';
    const idContact = '6684b9298e2f0917ec43ef5e';
    this.flowService.triggerFlow(idFlow, idChannel, idContact).subscribe({
      next: (flowExecution: FlowExecution) => {
        // console.log('flowExecution', flowExecution);
      }
    })

  }

}